import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>You have successfully registered for our email updates.</h2>
        <p>
        <a href="/" className="button">Home</a>

        </p>
      </header>
    </article>
  </Layout>
);

export default IndexPage;
